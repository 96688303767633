import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {
  uploadAnyDamage,
  UploadChasisImage,
  uploadExtraImage,
  uploadExtraImage1,
  uploadExtraImage2,
  uploadExtraImage3,
  uploadformatImage,
  UploadFrontImage,
  uploadFrontUnderCar,
  uploadGlassInsideImage,
  uploadGlassOutside,
  uploadLeft45,
  uploadLeftImage,
  uploadOdometerImage,
  UploadOpenBonnet,
  uploadOpenDickey,
  uploadRcBackImage,
  uploadRcFront,
  uploadRearImage,
  UploadRearUnderPart,
  UploadRight45Image,
  uploadRightImage,
  uploadRpmImage,
  uploadSignature,
  uploadTyreImage,
} from '../../redux/Cases/CaseSlice';

const ImageUpload = () => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [urls, setUrls] = useState([]);

  const actionsArray = [
    (img) => dispatch(UploadChasisImage({ image: img })),
    (img) => dispatch(uploadformatImage({ image: img })),
    (img) => dispatch(UploadOpenBonnet({ image: img })),
    (img) => dispatch(UploadRight45Image({ image: img })),
    (img) => dispatch(uploadFrontUnderCar({ image: img })),
    (img) => dispatch(uploadLeft45({ image: img })),
    (img) => dispatch(uploadLeftImage({ image: img })),
    (img) => dispatch(uploadRearImage({ image: img })),
    (img) => dispatch(UploadRearUnderPart({ image: img })),
    (img) => dispatch(uploadOpenDickey({ image: img })),
    (img) => dispatch(uploadRightImage({ image: img })),
    (img) => dispatch(uploadTyreImage({ image: img })),
    (img) => dispatch(uploadGlassOutside({ image: img })),
    (img) => dispatch(uploadGlassInsideImage({ image: img })),
    (img) => dispatch(uploadOdometerImage({ image: img })),
    (img) => dispatch(uploadRpmImage({ image: img })),
    (img) => dispatch(uploadAnyDamage({ image: img })),
    (img) => dispatch(uploadExtraImage({ image: img })),
    (img) => dispatch(uploadExtraImage1({ image: img })),
    (img) => dispatch(uploadExtraImage2({ image: img })),
    (img) => dispatch(uploadExtraImage3({ image: img })),
    (img) => dispatch(uploadSignature({ image: img })),
    (img) => dispatch(uploadRcFront({ image: img })),
    (img) => dispatch(uploadRcBackImage({ image: img })),
  ];

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((prevImages) => [...prevImages, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const cloudName = 'dexterous-technology';
  const uploadPreset = 'yrevfjck';

  const handleUpload = async () => {
    setLoading(true);
    const uploaders = images.map((img) => {
      const formData = new FormData();
      formData.append('file', img);
      formData.append('upload_preset', uploadPreset);
      formData.append('cloud_name', cloudName);
      return axios
        .post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, formData, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' },
        })
        .then((response) => {
          const data = response.data;
          const fileURL = data.secure_url;
          setUrls((prevState) => [...prevState, fileURL]);
        });
    });

    try {
      await axios.all(uploaders);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const updateImage = async () => {
    await handleUpload();
    images.forEach((image, index) => {
      if (actionsArray[index]) {
        actionsArray[index](image);
      }
    });
  };

  return (
    <div>
      <div style={{ color: '#000', marginBottom: '5px' }}
        className="text-sm font-medium text-default-900"
      >
        Upload Multiple Images
        <span style={{ color: 'red', paddingLeft: '5px' }}>***</span>
      </div>
      <input type="file" multiple onChange={handleFileChange} />
      <button onClick={() => updateImage()} style={{ backgroundColor: '#4d44b5', color: '#fff', padding: '10px 5px', borderRadius: '5px' }}>Upload Images</button>
      {loading && <p>Uploading...</p>}
    </div>
  );
};

export default ImageUpload;
