import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminPost, valiadteEmail, valiadteNumber } from "../../redux/Authentication/AuthSlice";
import { createEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import { CaseActions, UploadChasisImage, UploadFrontImage, UploadOpenBonnet, UploadRearUnderPart, UploadRight45Image, createCase, selfieWithVehicle, selfieWithVehicleUpload, sendVehicleNumber, uploadAnyDamage, uploadExtraImage, uploadExtraImage1, uploadExtraImage2, uploadExtraImage3, uploadFrontUnderCar, uploadGlassInsideImage, uploadGlassOutside, uploadLeft45, uploadLeftImage, uploadOdometerImage, uploadOpenDickey, uploadRcBackImage, uploadRcFront, uploadRearImage, uploadRightImage, uploadRpmImage, uploadSignature, uploadTyreImage, uploadformatImage } from "../../redux/Cases/CaseSlice";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import Spinner from 'react-bootstrap/Spinner';
import FourWheelerEditReport4 from "../Reports/FourWheelerEditReport4";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TextField } from "@mui/material";
import ImageUpload from "./UploadImage";

const AddThreeWheelerCase = () => {
    const dispatch = useDispatch();
    const params = useParams()
    const navigate = useNavigate()
    const { totalClients } = useSelector((store) => store.client);
    const { selfieWithVehicleImage, isSelfieWithAgentLoading, chasisImage, isChasisImageLoading,
        IsformatImageLoading,
        formatImage,
        isOpenBonnetLoading,
        openBonnet,
        isRight45ImageLoading,
        right45image,
        frontimage,
        isFrontImageLoading,
        frontUnderCarImage,
        isFrontUnderCarImageLoading,
        isLeft45Loading,
        left45image,
        isLeftImageLoading,
        leftImage,
        rearImage,
        isRearImageLoading,
        rearUnderPartImage,
        isRearUnderPartLoading,
        openDickeyImage,
        isOpenDickeyImageLoading,
        rightImage,
        isRightImageLoading,
        tyreImage,
        isTyreImageLoading,
        glassOutsideImage,
        isGlassOutsideImageLoading,
        glassInsideImage,
        isGlassInsideImageLoading,
        odometerImage,
        isOdometerImageLoading,
        rpmImage,
        isRpmImageLoading,
        anyDamage,
        isAnyDamageLoading,
        extraImage,
        isExtraImageLoading,
        extraImage1,
        isExtraImage1Loading,
        extraImage2,
        isExtraImage2Loading,
        extraImage3,
        isExtraImage3Loading,
        signatureImage,
        isSignatureImageLoading,
        rcFrontImage,
        isRcFrontImageLoading,
        rcBackImage,
        isRcBackImageLoading
    } = useSelector((store) => store.cases)
    const [assetType, setAssetType] = useState("");
    const [assetTypeError, setAssetTypeError] = useState("");
    const [caseType, setCaseType] = useState("");
    const [caseTypeError, setCaseTypeError] = useState("");
    const [valuationPurpose, setValuationPurpose] = useState('Assesment of fair market value');
    const [valuationPurposeError, setValuationPurposeError] = useState('');
    const [requesterID, setRequesterID] = useState("");
    const [requesterName, setRequesterName] = useState("");
    const [requesterNameError, setRequesterNameError] = useState("");
    const [requesterBranch, setRequesterBranch] = useState("");
    const [requesterBranchError, setRequesterBranchError] = useState("");
    const [requesterPhone, setRequesterPhone] = useState("");
    const [requesterPhoneError, setRequesterPhoneError] = useState("");
    const [email, setEmail] = useState("");
    const [Cnfemail, setCnfEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [mobile, setMobile] = useState("");
    const [mobileCnf, setMobileCnf] = useState("");
    const [mobileError, setMobileError] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [address, setAddress] = useState("")
    const [addressError, setAddressError] = useState("")
    const [state, setState] = useState("")
    const [stateError, setStateError] = useState("")
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState("")
    const [cityError, setCityError] = useState("")
    const [productType, setProductType] = useState("")
    const [productTypeError, setProductTypeError] = useState("")
    const [productBrand, setProductBrand] = useState("")
    const [productBrandError, setProductBrandError] = useState("")
    const [vehicleLocation, setVehicleLocation] = useState('')
    const [vehicleLocationError, setVehicleLocationError] = useState('')
    const [model, setModel] = useState("")
    const [ModelError, setModelError] = useState("")
    const [variant, setVariant] = useState("")
    const [variantError, setVariantError] = useState("")
    const [loanNo, setLoanNo] = useState("")
    const [loanNoError, setLoanNoError] = useState("")
    const [docVerified, setDocVerified] = useState('');
    const [regdOwner, setRegdOwner] = useState("")
    const [regdOwnerError, setRegdOwnerError] = useState("")
    const [regdDate, setRegdDate] = useState("")
    const [regdDateError, setRegdDateError] = useState("")
    const [mfgDate, setMfgdate] = useState('');
    const [numberPlateType, setNumberPlateType] = useState('');
    const [regdNo, setRegdNo] = useState("")
    const [regdNoError, setRegdNoError] = useState("")
    const [vehicleData, setVehicleData] = useState({});
    const [dataFetched, setDataFetched] = useState(false)
    const [engNo, setEngNo] = useState("")
    const [engNoError, setEngNoError] = useState("")
    const [chasisNo, setChasisNo] = useState("")
    const [chasisNoError, setChasisNoError] = useState("")
    const [bodyType, setBodyType] = useState("")
    const [bodyTypeError, setBodyTypeError] = useState("")
    const [meterReading, setMeterReading] = useState('');
    const [fueltype, setFueltype] = useState("")
    const [fueltypeError, setFueltypeError] = useState("")
    const [vehicleColor, setVehicleColor] = useState("")
    const [vehicleColorError, setVehicleColorError] = useState("")
    const [ownerSerialNo, setOwnerSerialNo] = useState("")
    const [ownerSerialNoError, setOwnerSerialNoError] = useState("")
    const [hypothecationBank, setHypothecationBank] = useState("")
    const [hypothecationBankError, setHypothecationBankError] = useState("")
    const [insuranceCompany, setInsuranceCompany] = useState('');
    const [insuranceStatus, setInsuranceStatus] = useState('');
    const [cubicCapacity, setCubicCapacity] = useState("")
    const [cubicCapacityError, setCubicCapacityError] = useState("")
    const [seatingCapacity, setSeatingCapacity] = useState("")
    const [eatingCapacityError, setSeatingCapacityError] = useState("")
    const [vehicleWeight, setVehicleWeight] = useState("")
    const [vehicleWeightError, setVehicleWeightError] = useState("")

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showLoader, setShowLoader] = useState(false)
    const [videoRecord, setVideoRecord] = useState('')

    const [role, setRole] = useState("")
    const fileTypes = ["JPG", "PNG", "GIF"];

    const [odometerType, setOdometerType] = useState('');
    const [fitnessExpiryDate, setFitnessExpiryDate] = useState('');
    const [roadTaxValidityDate, setRoadTaxValidity] = useState('');
    const [engineCondition, setEngineCondition] = useState('');
    const [engineConditionError, setEngineConditionError] = useState('');
    const [fuelTankCondition, setFuelTankCondition] = useState('');
    const [engineShield, setEngineShield] = useState('');
    const [transmissionType, setTransmissionType] = useState('');
    const [transmissionTypeError, setTransmissionTypeError] = useState('');
    const [transmissionCondition, setTransmissionCondition] = useState('');
    const [transmissionConditionError, setTransmissionConditionError] = useState('');
    const [suspensionCondition, setSuspensionCondition] = useState('');
    const [suspensionConditionError, setSuspensionConditionError] = useState('');
    const [electricalCondition, setElectricalCondition] = useState('');
    const [electricalConditionError, setElectricalConditionError] = useState('');
    const [interiorCondition, setInteriorCondition] = useState('');
    const [InteriorConditionError, setInteriorConditionError] = useState('');
    const [exteriorCondition, setExteriorCondition] = useState('');
    const [exteriorConditionError, setExteriorConditionError] = useState('');
    const [frontBreakCondition, setFrontBreakCondition] = useState('');
    const [frontBreakConditionError, setFrontBreakConditionError] = useState('');
    const [rearBreakCondition, setRearBreakCondition] = useState('');
    const [rearBreakConditionError, setRearBreakConditionError] = useState('');
    const [handBrake, setHandBrake] = useState('');
    const [handBrakeError, setHandBrakeError] = useState('');
    const [wheelType, setWheelType] = useState('');
    const [wheelTypeError, setWheelTypeError] = useState('');
    const [vehicleFront, setVehicleFront] = useState('');
    const [vehicleFrontError, setVehicleFrontError] = useState('');
    const [vehicleLhRear, setVehicleLhRear] = useState('');
    const [vehicleLhRearError, setVehicleLhRearError] = useState('');
    const [vehicleRhRear, setVehicleRhRear] = useState('');
    const [vehicleRhRearError, setVehicleRhRearError] = useState('');
    const [vehicleSpare, setVehicleSpare] = useState('');
    const [vehicleSpareError, setVehicleSpareError] = useState('');
    const [totalTyre, setTotalTyre] = useState('');
    const [totalTyreError, setTotalTyreError] = useState('');
    const [availableTyre, setAvailableTyre] = useState('');
    const [availableTyreError, setAvailableTyreError] = useState('');
    const [missingTyre, setMissingTyre] = useState('');
    const [missingTyreError, setMissingTyreError] = useState('');
    const [spareTyre, setSpareTyre] = useState('');
    const [spareTyreError, setSpareTyreError] = useState('');
    const [vehicleSeat, setVehicleSeat] = useState('');
    const [vehicleSeatError, setVehicleSeatError] = useState('');
    const [vehicleInteriorTrims, setVehicleInteriorTrims] = useState('');
    const [vehicleInteriorTrimsError, setVehicleInteriorTrimsError] = useState('');
    const [vehicleInsideRearViewMirror, setVehicleInsideRearViewMirror] = useState('');
    const [vehicleInsideRearViewMirrorError, setVehicleInsideRearViewMirrorError] = useState('');
    const [vehicleBodyType, setVehicleBodyType] = useState('');
    const [vehicleBodyTypeError, setVehicleBodyTypeError] = useState('');
    const [vehicleFrontMudGuard, setVehicleFrontMudGuard] = useState('');
    const [vehicleFrontMudGuardError, setVehicleFrontMudGuardError] = useState('');
    const [vehicleRearWheelMudGuard, setVehicleRearWheelMudGuard] = useState('');
    const [vehicleRearWheelMudGuardError, setVehicleRearWheelMudGuardError] = useState('');
    const [vehicleWindScreen, setVehicleWindScreen] = useState('');
    const [vehicleWindScreenError, setVehicleWindScreenError] = useState('');
    const [vehicleWindScreenGlass, setVehicleWindScreenGlass] = useState('');
    const [vehicleWindScreenGlassError, setVehicleWindScreenGlassError] = useState('');
    const [vehicleSideDoor, setVehicleSideDoor] = useState('');
    const [vehicleSideDoorError, setVehicleSideDoorError] = useState('');
    const [vehicleDoorGlasses, setVehicleDoorGlasses] = useState('');
    const [vehicleDoorGlassesError, setVehicleDoorGlassesError] = useState('');
    const [vehicleOutsideRearViewMirror, setVehicleOutsideRearViewMirror] = useState('');
    const [vehicleOutsideRearViewMirrorError, setVehicleOutsideRearViewMirrorError] = useState('');
    const [vehiclePaint, setVehiclePaint] = useState('');
    const [vehiclePaintError, setVehiclePaintError] = useState('');
    const [vehiclePaintCondition, setVehiclePaintCondition] = useState('');
    const [vehiclePaintConditionError, setVehiclePaintConditionError] = useState('');
    const [vehicleOverAllBodyCondition, setVehicleOverAllBodyCondition] = useState('');
    const [vehicleOverAllBodyConditionError, setVehicleOverAllBodyConditionError] = useState('');
    const [vehicleBattery, setVehicleBattery] = useState('');
    const [vehicleBatteryError, setVehicleBatteryError] = useState('');
    const [vehicleHeadLamp, setVehicleHeadLamp] = useState('');
    const [vehicleHeadLampError, setVehicleHeadLampError] = useState('');
    const [vehicleFrontIndicators, setVehicleFrontIndicators] = useState('');
    const [vehicleFrontIndicatorsError, setVehicleFrontIndicatorsError] = useState('');
    const [vehicleRearIndicators, setVehicleRearIndicators] = useState('');
    const [vehicleRearIndicatorsError, setVehicleRearIndicatorsError] = useState('');
    const [vehicleTailLamp, setVehicleTailLamp] = useState('');
    const [vehicleTailLampError, setVehicleTailLampError] = useState('');
    const [vehicleToolkit, setVehicleToolkit] = useState('');
    const [vehicleToolkitError, setVehicleToolkitError] = useState('');
    const [vehicleOthersAccessories, setVehicleOthersAccessories] = useState('');
    const [vehicleOthersAccessoriesError, setVehicleOthersAccessoriesError] = useState('');
    const [accidental, setAccidental] = useState('');
    const [accidentalError, setAccidentalError] = useState('');
    const [otherRepair, setotherRepair] = useState('');
    const [otherRepairError, setotherRepairError] = useState('');
    const [maintenanceLevel, setMaintenanceLevel] = useState('');
    const [maintenanceLevelError, setMaintenanceLevelError] = useState('');

    const [yourRemark, setYourRemark] = useState('');
    const [exShowRoomPrice, setExShowRoomPrice] =
        useState('');
    const [exShowRoomPriceError, setExShowRoomPriceError] =
        useState('');
    const [estimatedPrice, setEstimatedPrice] =
        useState('');
    const [estimatedPriceError, setEstimatedPriceError] =
        useState('');

    const [buttonLoading, setButtonLoading] = useState(false)

    const [clientList, setClientList] = useState([])
    useEffect(() => {
        const client = totalClients.map((indClient, index) => ({
            id: index,
            _id:indClient._id,
            name: indClient.name,
            phone: indClient.phone,
            organisation: indClient.organisation
        }));
        setClientList(client);
    }, [totalClients])
    const handleRequester = (e) => {
        const { organisation, phone ,_id} = e.target.value
        setRequesterID(_id)
        setRequesterBranch(organisation)
        setRequesterPhone(phone)
    }
    console.log(requesterID,'requesterID');
    const selectCaseType = [
        {
            id: 1,
            name: "Revaluation",
        },
        {
            id: 2,
            name: "Repossession",
        },
        {
            id: 3,
            name: "Retail",
        },
        {
            id: 4,
            name: "Insurance Idv",
        },
        {
            id: 5,
            name: "Repurchase",
        },
    ];
    const selectCaseChange = (e) => {
        const { value } = e.target;
        setCaseType(value);
    };
    const selectRemarkType = [
        {
            id: 1,
            name: "Poor",
        },
        {
            id: 2,
            name: "Average",
        },
        {
            id: 3,
            name: "Satisfactory",
        },
        {
            id: 4,
            name: "Good",
        },
        {
            id: 5,
            name: "Excellent",
        },
    ];

    const selectRemarkChange = (e) => {
        const { value } = e.target;
        const index = e.nativeEvent.target.selectedIndex;
        const text = e.nativeEvent.target[index].text;
        setYourRemark(value);
    };
    const selectAssetType = [
        {
            id: 1,
            name: "Two Wheeler",
        },
        {
            id: 2,
            name: "Four Wheeler",
        },
        {
            id: 3,
            name: "Commercial",
        },
        {
            id: 4,
            name: "Construction Equipment",
        },
        {
            id: 5,
            name: "Three Wheeler",
        },
        {
            id: 6,
            name: "Farm Equipment",
        },
    ];
    const selectAssetChange = (e) => {
        const { value } = e.target;
        setAssetType(value);
    };

    const handleRequesterPhn = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setRequesterPhone(value)
    }

    const stateList = [
        { id: 1, name: "Andhra Pradesh" },
        { id: 2, name: "Arunachal Pradesh" },
        { id: 3, name: "Assam" },
        { id: 4, name: "Bihar" },
        { id: 5, name: "Chhattisgarh" },
        { id: 6, name: "Goa" },
        { id: 7, name: "Gujarat" },
        { id: 8, name: "Haryana" },
        { id: 9, name: "Himachal Pradesh" },
        { id: 10, name: "Jharkhand" },
        { id: 11, name: "Karnataka" },
        { id: 12, name: "Kerala" },
        { id: 13, name: "Madhya Pradesh" },
        { id: 14, name: "Maharashtra" },
        { id: 15, name: "Manipur" },
        { id: 16, name: "Meghalaya" },
        { id: 17, name: "Mizoram" },
        { id: 18, name: "Nagaland" },
        { id: 19, name: "Odisha" },
        { id: 20, name: "Punjab" },
        { id: 21, name: "Rajasthan" },
        { id: 22, name: "Sikkim" },
        { id: 23, name: "Tamil Nadu" },
        { id: 24, name: "Telangana" },
        { id: 25, name: "Tripura" },
        { id: 26, name: "Uttar Pradesh" },
        { id: 27, name: "Uttarakhand" },
        { id: 28, name: "West Bengal" },
        { id: 29, name: "Andaman and Nicobar Islands" },
        { id: 30, name: "Chandigarh" },
        { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
        { id: 32, name: "Delhi" },
        { id: 33, name: "Ladakh" },
        { id: 34, name: "Lakshadweep" },
        { id: 35, name: "Puducherry" }
    ];
    const selectStateChange = (e) => {
        const { value } = e.target;
        setState(value);
    };
    useEffect(() => {
        if (state) {
            const formData = {
                country: 'India',
                state: state.toLocaleUpperCase(),
            };
            const getCity = async () => {
                try {
                    const config = {
                        headers: { 'Content-Type': 'application/json' },
                    };
                    const url = 'https://countriesnow.space/api/v0.1/countries/state/cities';
                    const resp = await axios.post(url, formData, config);
                    if (!resp.data.error) {
                        const citiesData = resp.data.data.map((city, index) => ({
                            id: index + 1,
                            name: city.toLowerCase().replace(/\s/g, '_'),
                        }));
                        setCityList(citiesData);
                    } else {
                        console.error("Error in response:", resp.data.error); // Log the error for debugging
                    }
                } catch (error) {
                    console.error("Error:", error); // Log any other errors for debugging
                }
            };

            getCity();
        }
    }, [state]);

    const selectCityChange = (e) => {
        const { value } = e.target;
        setCity(value);
    };

    const handleRegdNo = async (e) => {
        const inputValue = e.target.value.toUpperCase();
        setRegdNo(inputValue);
        setDataFetched(false); // Reset data fetched status

        if (inputValue.length === 9 || inputValue.length === 10) {
            try {
                // let newData = await fetchVehicleData(inputValue);
                const formData = {
                    "regdNo": inputValue,
                }
                const vehicle = await dispatch(sendVehicleNumber(formData))
                setVehicleData(vehicle.payload.response.result);
                setDataFetched(true);
            } catch (error) {
                setVehicleData(null); // Reset vehicle data
            }
        } else {
            setVehicleData(null); // Reset vehicle data if input length is invalid
        }
    };

    useEffect(() => {
        // Fetch data again if required properties are null after initial fetch
        if (dataFetched && vehicleData) {
            const {
                rcFinancer,
                vehicleTaxUpto,
                permitValidUpto,
                permitType,
                vehicleSeatCapacity,
                grossVehicleWeight,
                regAuthority
            } = vehicleData;

            if (
                !rcFinancer ||
                !vehicleTaxUpto ||
                !permitValidUpto ||
                !permitType ||
                !vehicleSeatCapacity ||
                !grossVehicleWeight ||
                !regAuthority
            ) {
                // Required properties are still null, fetch again
                handleRegdNo({ target: { value: regdNo } });
            }
        }
    }, [dataFetched, vehicleData, regdNo]);

    const documents = [
        { name: 'Verified', id: 1 },
        { name: 'Not Verified', id: 2 },
    ];
    const selectDocuementVerifiedTypeChange = (e) => {
        const { value } = e.target;
        setDocVerified(value);
    };
    const number = [
        { name: 'White', id: 1 },
        { name: 'Yellow', id: 2 },
        { name: 'Blue', id: 3 },
        { name: 'Black', id: 4 },
    ];
    const selectNumberPlateTypeChange = (e) => {
        const { value } = e.target;
        setNumberPlateType(value);
    };
    const options = [
        { name: 'Yes', id: 1 },
        { name: 'No', id: 2 },
    ];
    const Features = [
        { name: "2WD", id: 1 },
        { name: "Manual", id: 2 },
        { name: "Normal", id: 3 },
    ];
    const condition = [
        { name: "Poor", id: 1 },
        { name: "Average", id: 2 },
        { name: "Satisfactory", id: 3 },
        { name: "Good", id: 4 },
        { name: "Excellent", id: 5 },
        { name: "NA", id: 6 },
    ];
    const vehcileBodyType = [
        { name: 'PASSENGER', id: 1 },
        { name: 'GOODS', id: 2 },
    ];
    const reqCondition = [
        { name: "Poor", id: 1 },
        { name: "Average", id: 2 },
        { name: "Satisfactory", id: 3 },
        { name: "Good", id: 4 },
        { name: "Excellent", id: 5 }
    ];
    const list = [
        { name: "10", id: 1 },
        { name: "20", id: 2 },
        { name: "30", id: 3 },
        { name: "40", id: 4 },
        { name: "50", id: 5 },
        { name: "60", id: 6 },
        { name: "70", id: 7 },
        { name: "80", id: 8 },
        { name: "90", id: 9 },
        { name: "100", id: 10 },
    ];
    const qualityList = [
        { name: "Original", id: 1 },
        { name: "Replaced", id: 2 },
    ];
    const wheelTypeList = [
        { name: "Alloy", id: 1 },
        { name: "Steel", value: 2 },
        { name: "Rim", value: 3 },
    ];
    const carSteeringTypeList = [
        { name: 'RACK AND PINION STEERING', id: 1 },
        { name: 'POWER STEERING', id: 2 },
        { name: 'RECIRCULATING BALL STEERING', id: 3 },
    ];
    const selectInsuranceStatusChange = (e) => {
        const { value } = e.target;
        setInsuranceStatus(value);
    };

    const handleFileChange = (event) => {
        setSelectedVideo(event.target.files[0])
    };

    const handleUpload = async () => {
        setShowLoader(true)
        const apiKey = '918618947122224';
        const cloudName = 'dexterous-technology';
        const uploadPreset = 'yrevfjck';
        if (selectedVideo) {
            const formData = new FormData();
            formData.append('file', selectedVideo);
            formData.append('upload_preset', 'yrevfjck'); // Replace 'your_upload_preset' with your Cloudinary upload preset

            try {
                const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/video/upload`, {
                    method: 'POST',
                    body: formData
                });

                if (response.ok) {
                    const responseData = await response.json();
                    setVideoRecord(responseData.secure_url)
                    document.getElementById('videouploading').value = '';
                    setShowLoader(false)
                } else {
                    // Handle upload failure
                    console.error('Failed to upload video to Cloudinary:', response.statusText);
                }
            } catch (error) {
                // Handle network or server errors
                console.error('Error uploading video to Cloudinary:', error);
            }
        } else {
            console.log("No video selected.");
        }
    };

    const handleSelfieWithVehicleChange = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(selfieWithVehicleUpload({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleChasisChange = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(UploadChasisImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleFormatChange = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadformatImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleOpenBonnetChange = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(UploadOpenBonnet({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleright45Change = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(UploadRight45Image({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const frontImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(UploadFrontImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const frontUnderCarImg = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadFrontUnderCar({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handlesetLeft45Image = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadLeft45({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handlesetLeftImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadLeftImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handlesetRearImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadRearImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handlerearUnderPartImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(UploadRearUnderPart({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handlerearOpenDickeyImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadOpenDickey({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const HandlerightImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadRightImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handlesetTyreImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadTyreImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleGlassOutsideImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadGlassOutside({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleGlassInsideImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadGlassInsideImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleOdometerImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadOdometerImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleRpmImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadRpmImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleAnyDamage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadAnyDamage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleExtraImage = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadExtraImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleExtraImage1 = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadExtraImage1({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleExtraImage2 = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadExtraImage2({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleExtraImage3 = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadExtraImage3({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleSignature = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadSignature({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleFrontRc = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadRcFront({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };
    const handleBackRc = async (e) => {
        const files = Array.from(e.target.files);
        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    dispatch(uploadRcBackImage({ image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        });
    };

    const odometerList = [
        { id: 1, name: 'Digital' },
        { id: 2, name: 'Normal' },
    ];
    const typeList = [
        { id: 1, name: 'Manual' },
        { id: 2, name: 'Auto' },
    ];
    const selectOdometerTypeChange = (e) => {
        const { value } = e.target;
        setOdometerType(value);
    };
    const selectWheelTypeChange = (e) => {
        const { value } = e.target;
        setWheelType(value);
    };
    const selectEngineConditionChange = (e) => {
        const { value } = e.target;
        setEngineCondition(value);
    };
    const selectFuelTankConditionChange = (e) => {
        const { value } = e.target;
        setFuelTankCondition(value);
    };
    const selectEngineSheildConditionChange = (e) => {
        const { value } = e.target;
        setEngineShield(value);
    };
    const selectTransmissionTypeChange = (e) => {
        const { value } = e.target;
        setTransmissionType(value);
    };
    const selectTransmissionConditionChange = (e) => {
        const { value } = e.target;
        setTransmissionCondition(value);
    };
    const selectSuspensionConditionChange = (e) => {
        const { value } = e.target;
        setSuspensionCondition(value);
    };
    const selectElectricalConditionChange = (e) => {
        const { value } = e.target;
        setElectricalCondition(value);
    };
    const selectFrontBreakConditionChange = (e) => {
        const { value } = e.target;
        setFrontBreakCondition(value);
    };
    const selectRearBreakConditionChange = (e) => {
        const { value } = e.target;
        setRearBreakCondition(value);
    };
    const selectHandBreakConditionChange = (e) => {
        const { value } = e.target;
        setHandBrake(value);
    };
    const selectFrontTyreConditionChange = (e) => {
        const { value } = e.target;
        setVehicleFront(value);
    };
    const selectVehicleLhRearConditionChange = (e) => {
        const { value } = e.target;
        setVehicleLhRear(value);
    };
    const selectVehicleRhRearConditionChange = (e) => {
        const { value } = e.target;
        setVehicleRhRear(value);
    };
    const selectVehicleSpareTyreConditionChange = (e) => {
        const { value } = e.target;
        setVehicleSpare(value);
    };
    const selectVehicleSeatConditionChange = (e) => {
        const { value } = e.target;
        setVehicleSeat(value);
    };
    const selectVehicleInteriorTrimsConditionChange = (e) => {
        const { value } = e.target;
        setVehicleInteriorTrims(value);
    };
    const selectVehicleInsideRearViewMirrorChange = (e) => {
        const { value } = e.target;
        setVehicleInsideRearViewMirror(value);
    };
    const selectVehicleBodyTypeChange = (e) => {
        const { value } = e.target;
        setVehicleBodyType(value);
    };
    const selectVehicleFrontMudGuardCondtionChange = (e) => {
        const { value } = e.target;
        setVehicleFrontMudGuard(value);
    };
    const selectVehicleRearWheelMudGuardCondtionChange = (e) => {
        const { value } = e.target;
        setVehicleRearWheelMudGuard(value);
    };
    const selectVehicleWindScreenCondtionChange = (e) => {
        const { value } = e.target;
        setVehicleWindScreen(value);
    };
    const selectVehicleWindScreenGlassChange = (e) => {
        const { value } = e.target;
        setVehicleWindScreenGlass(value);
    };
    const selectVehicleSideDoorChange = (e) => {
        const { value } = e.target;
        setVehicleSideDoor(value);
    };
    const selectVehicleDoorGlassesConditionChange = (e) => {
        const { value } = e.target;
        setVehicleDoorGlasses(value);
    };
    const selectVehicleOutsideRearViewMirrorChange = (e) => {
        const { value } = e.target;
        setVehicleOutsideRearViewMirror(value);
    };
    const selectPaintChange = (e) => {
        const { value } = e.target;
        setVehiclePaint(value);
    };
    const selectPaintConditionChange = (e) => {
        const { value } = e.target;
        setVehiclePaintCondition(value);
    };
    const selectOverAllBodyConditionChange = (e) => {
        const { value } = e.target;
        setVehicleOverAllBodyCondition(value);
    };
    const selectVehicleBatteryChange = (e) => {
        const { value } = e.target;
        setVehicleBattery(value);
    };
    const SelectVehicleHeadLampChange = (e) => {
        const { value } = e.target;
        setVehicleHeadLamp(value);
    };
    const SelectFrontIndicatorsChange = (e) => {
        const { value } = e.target;
        setVehicleFrontIndicators(value);
    };
    const SelectRearIndicatorsChange = (e) => {
        const { value } = e.target;
        setVehicleRearIndicators(value);
    };
    const SelectTailLampChange = (e) => {
        const { value } = e.target;
        setVehicleTailLamp(value);
    };
    const SelectVehicleToolkitChange = (e) => {
        const { value } = e.target;
        setVehicleToolkit(value);
    };
    const SelectVehicleOthersAccessoriesChange = (e) => {
        const { value } = e.target;
        setVehicleOthersAccessories(value);
    };
    const SelectAccidentalStatus = (e) => {
        const { value } = e.target;
        setAccidental(value);
    };
    const SelectotherRepairStatus = (e) => {
        const { value } = e.target;
        setotherRepair(value);
    };
    const SelectMaintenanceLevelCondition = (e) => {
        const { value } = e.target;
        setMaintenanceLevel(value);
    };


    const [btnPress, setbtnPress] = useState(false);

    useEffect(() => {
        if (btnPress === true) {
            if (name === "") {
                setNameError("please enter title");
            } else {
                setNameError("");
            }
            if (Cnfemail === "") {
                setEmailError("please enter email");
            } else {
                setEmailError("");
            }
            if (mobileCnf.length !== 10) {
                setMobileError("please enter mobile");
            } else {
                setMobileError("");
            }
        }
    }, [btnPress, name, Cnfemail, mobileCnf]);

    const setNumberChange = async (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setMobileError("");
        setMobile(value);
        if (value.length === 10) {
            // const verifyNumber = await dispatch(valiadteNumber(value));
            // if (verifyNumber.payload.success) {
            //     setMobileError("Number already exist");
            // } else {
            // }
            setMobileError("");
            setMobileCnf(value);
        } else {
            setMobileError("Please Enter Valid Number");
        }
    };

    const emailChange = async (e) => {
        const value = e;
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmail(value);
        setEmailError("");
        if (value.length > 0) {
            if (value.match(mailformat)) {
                // const verifyEmail = await dispatch(valiadteEmail(value));
                // if (verifyEmail.payload.success) {
                //     setEmailError("Number already exist");
                // } else {
                // }
                setEmailError("");
                setCnfEmail(value);
            } else {
                setEmailError("please enter correct Email format");
            }
        } else {
            setEmailError("please enter correct Email format");
        }
    };

    const cancelClick = async (e) => {
        e.preventDefault();
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        setButtonLoading(true)
        if (name === "") {
            setNameError("please enter title");
        } else {
            setNameError("");
        }
        if (Cnfemail === "") {
            setEmailError("please enter email");
        } else {
            setEmailError("");
        }
        if (mobileCnf.length !== 10) {
            setMobileError("please enter mobile");
        } else {
            setMobileError("");
        }
        if (name !== "" && Cnfemail !== "" && mobileCnf !== "") {
            const formData = {
                assetType: assetType,
                caseType: caseType,
                valuationPurpose: valuationPurpose,
                vehicleLocation: vehicleLocation,
                requesterID:requesterID,
                requesterName: requesterName,
                requesterBranch: requesterBranch,
                requesterPhone: requesterPhone,
                name: name,
                mobile: mobileCnf,
                email: Cnfemail,
                address: address,
                state: state,
                city: city,
                regdNo: regdNo,
                productType: productType,
                brand: productBrand,
                model: model,
                variant: variant,
                loanAgreementNo: loanNo,
                documentVerification: docVerified,
                regdOwner: regdOwner,
                regdDate: regdDate,
                numberPlate: numberPlateType,
                engNo: engNo,
                chasisNo: chasisNo,
                bodyType: bodyType,
                manufacturingDate: mfgDate,
                fueltype: fueltype,
                vehicleColor: vehicleColor,
                ownerSerialNo: ownerSerialNo,
                hypothecationBank: hypothecationBank,
                cubicCapacity: cubicCapacity,
                seatingCapacity: seatingCapacity,
                vehicleWeight: vehicleWeight,
                videos: videoRecord,
                images: {
                    selfieWithVehicle: selfieWithVehicleImage,
                    chasisImage: chasisImage,
                    format: formatImage,
                    openBonnet: openBonnet,
                    right45: right45image,
                    frontImage: frontimage,
                    frontUnderCar: frontUnderCarImage,
                    left45: left45image,
                    leftImage: leftImage,
                    rearImage: rearImage,
                    rearUnderPart: rearUnderPartImage,
                    openDickey: openDickeyImage,
                    rightImage: rightImage,
                    tyreImage: tyreImage,
                    glassOutside: glassOutsideImage,
                    glassInside: glassInsideImage,
                    odometerImage: odometerImage,
                    rpmImage: rpmImage,
                    anyDamage: anyDamage,
                    extraImage: extraImage,
                    extraImage1: extraImage1,
                    extraImage2: extraImage2,
                    extraImage3: extraImage3,
                },
                signNdoc: {
                    sign: signatureImage,
                    rcFront: rcFrontImage,
                    rcBack: rcBackImage,
                },
                threeWheeler: {
                    odometerType: odometerType,
                    fitnessExpiry: fitnessExpiryDate,
                    roadTaxValidity: roadTaxValidityDate,
                    engineCondition: engineCondition,
                    fuelTankConditiuon: fuelTankCondition,
                    engineShield: engineShield,
                    transmissionType: transmissionType,
                    transmissionCondition: transmissionCondition,
                    suspensionCondition: suspensionCondition,
                    frontBrakeCondition: frontBreakCondition,
                    rearBrakeCondition: rearBreakCondition,
                    handBrake: handBrake,
                    wheelType: wheelType,
                    front: vehicleFront,
                    lhRear: vehicleLhRear,
                    rhRear: vehicleRhRear,
                    spare: vehicleSpare,
                    totalTyres: totalTyre,
                    availableTyre: availableTyre,
                    missingTyre: missingTyre,
                    spareTyre: spareTyre,
                    seats: vehicleSeat,
                    interiorTrims: vehicleInteriorTrims,
                    insideRearViewMirror: vehicleInsideRearViewMirror,
                    vehiclebodyType: vehicleBodyType,
                    frontMudguard: vehicleFrontMudGuard,
                    rearWheelsMudguards: vehicleRearWheelMudGuard,
                    windScreenCondition: vehicleWindScreen,
                    windScreenGlassStatus: vehicleWindScreenGlass,
                    sideDoors: vehicleSideDoor,
                    doorGlasses: vehicleDoorGlasses,
                    outsideRearViewMirror: vehicleOutsideRearViewMirror,
                    paintStatus: vehiclePaint,
                    paintCondition: vehiclePaintCondition,
                    overallBodyCondition: vehicleOverAllBodyCondition,
                    battery: vehicleBattery,
                    headLamp: vehicleHeadLamp,
                    frontIndicators: vehicleFrontIndicators,
                    rearIndicators: vehicleRearIndicators,
                    tailLamp: vehicleTailLamp,
                    electricalCondition: electricalCondition,
                    toolKit: vehicleToolkit,
                    othersAccessories: vehicleOthersAccessories,
                    accidentalStatus: accidental,
                    maintenanceLevel: maintenanceLevel,
                    otherRepairs: otherRepair
                },
                yourRemark: yourRemark,
                valuation: estimatedPrice,
                marketPrice: exShowRoomPrice,
                status: 4,
                statusText: "Case Submitted",
            };
            const newcase = await dispatch(createCase(formData))
            if (newcase.payload.success) {
                alert("Case created successfully");
                dispatch(CaseActions.afterPost());
                setEmail("");
                setCnfEmail("");
                setMobile("");
                setMobileCnf("");
                setName("");
                setRole("")
                setAddress("")
                setState("")
                setCity("")
                setProductType("")
                setProductBrand("")
                setModel("")
                setVariant("")
                setLoanNo("")
                setDocVerified("")
                setRegdOwner("")
                setRegdDate("")
                setMfgdate("")
                setNumberPlateType("")
                setRegdNo("")
                setEngNo("")
                setChasisNo("")
                setBodyType("")
                setFueltype("")
                setVehicleColor("")
                setOwnerSerialNo("")
                setHypothecationBank("")
                setCubicCapacity("")
                setSeatingCapacity("")
                setVehicleWeight("")
                setAccidental("")
                setButtonLoading(false)
                navigate('/admin/submittedcases')
            }
        } else {
            setbtnPress(true);
            setButtonLoading(false)
        }
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-2 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-2 px-5"
                            style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                        >
                            <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                                Add Three Wheeler Case Details
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-3 gap-4">
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Case Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-select-label">Case Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={caseType}
                                            label="Case Type"
                                            onChange={(e) => selectCaseChange(e)}
                                        >
                                            {selectCaseType.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fAssetType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Asset Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Asset-Type">Asset Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-Asset-Type"
                                            id="demo-simple-select"
                                            value={assetType}
                                            label="Asset Type"
                                            onChange={(e) => selectAssetChange(e)}
                                        >
                                            {selectAssetType.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fValuationPurpose"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Purpose Of Valuation *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Purpose Of Valuation"
                                            variant="outlined"
                                            className={
                                                valuationPurposeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={valuationPurpose}
                                            onChange={(e) => setValuationPurpose(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Valuation Requested By *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-requesterName" className="inputcenter">Valuation Requested By</InputLabel>
                                        <Select
                                            labelId="demo-simple-requesterName"
                                            id="demo-simple-select"
                                            value={requesterName}
                                            label="requesterName"
                                            onChange={(e) => { handleRequester(e); setRequesterName(e.target.value.name) }}
                                            style={{ height: '38px' }}
                                            inputProps={{ style: { textAlign: 'center' } }}
                                        >
                                            {clientList.map((test, index) =>
                                                <MenuItem value={test} key={index} selected={test._id===requesterID?true:false}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Name"
                                            variant="outlined"
                                            className={
                                                "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={requesterName}
                                            onChange={(e) => setRequesterName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Branch Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Branch Name"
                                            variant="outlined"
                                            className={
                                                requesterBranchError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={requesterBranch}
                                            onChange={(e) => setRequesterBranch(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Requester Phone *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Requester Phone"
                                            variant="outlined"
                                            className={
                                                requesterPhoneError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={requesterPhone}
                                            onChange={(e) => handleRequesterPhn(e)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Name"
                                            variant="outlined"
                                            className={
                                                nameError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer  Phone *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Phone"
                                            variant="outlined"
                                            className={
                                                mobileError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={mobile}
                                            onChange={(e) => setNumberChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="lName"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Email *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Email"
                                            variant="outlined"
                                            className={
                                                emailError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={email}
                                            onChange={(e) => emailChange(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="Address"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Customer Address *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Customer Address"
                                            variant="outlined"
                                            className={
                                                addressError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fState"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Select State *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-State-Type">State Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-State-Type"
                                            id="demo-simple-select"
                                            value={state}
                                            label="State"
                                            onChange={(e) => selectStateChange(e)}
                                        >
                                            {stateList.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fCity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Select City *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-City-Type">City Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-City-Type"
                                            id="demo-simple-select"
                                            value={city}
                                            label="City"
                                            onChange={(e) => selectCityChange(e)}

                                        >
                                            {cityList.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        RegdNo *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Regd No"
                                            variant="outlined"
                                            className={
                                                regdNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={regdNo}
                                            onChange={(e) => setRegdNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehcileLocation"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Location *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Location"
                                            variant="outlined"
                                            className={
                                                vehicleLocationError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleLocation}
                                            onChange={(e) => setVehicleLocation(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="productType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle brand *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Brand"
                                            variant="outlined"
                                            className={
                                                productBrandError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={productBrand}
                                            onChange={(e) => setProductBrand(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="model"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle model *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Location"
                                            variant="outlined"
                                            className={
                                                ModelError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={model}
                                            onChange={(e) => setModel(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="variant"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle variant *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle variant"
                                            variant="outlined"
                                            className={
                                                variantError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={variant}
                                            onChange={(e) => setVariant(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="loanNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Loan Agreement No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Loan Agreement No"
                                            variant="outlined"
                                            className={
                                                loanNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={loanNo}
                                            onChange={(e) => setLoanNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fDocVerified"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Document Verified *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Document-Verified">Doc Verified</InputLabel>
                                        <Select
                                            labelId="demo-simple-Document-Verified"
                                            id="demo-simple-select"
                                            value={docVerified}
                                            label="Doc Verified"
                                            onChange={(e) => selectDocuementVerifiedTypeChange(e)}

                                        >
                                            {documents.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdOwner"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Regd Owner *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Regd Owner"
                                            variant="outlined"
                                            className={
                                                regdOwnerError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={regdOwner}
                                            onChange={(e) => setRegdOwner(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="regdOwner"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Regd Date *
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            regdDateError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="regdDate"
                                        placeholder="Enter product regd date"
                                        value={regdDate}
                                        onChange={(e) => setRegdDate(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fManufacturingDate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Manufacturing Date <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fManufacturingDate"
                                        placeholder="Enter Manufacturing Date"
                                        value={mfgDate}
                                        onChange={(e) => setMfgdate(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fNumberPlate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Number Plate Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Number-Plate-Type">Number Plate Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-Number-Plate-Type"
                                            id="demo-simple-select"
                                            value={numberPlateType}
                                            label="Number Plate Type"
                                            onChange={(e) => selectNumberPlateTypeChange(e)}

                                        >
                                            {number.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="engNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Engine No"
                                            variant="outlined"
                                            className={
                                                engNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={engNo}
                                            onChange={(e) => setEngNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="chasisNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Chasis No"
                                            variant="outlined"
                                            className={
                                                chasisNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={chasisNo}
                                            onChange={(e) => setChasisNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="bodyType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Body Type *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Body Type"
                                            variant="outlined"
                                            className={
                                                bodyTypeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield"
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={bodyType}
                                            onChange={(e) => setBodyType(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fMeterReading"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Meter Reading *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Meter Reading"
                                            variant="outlined"
                                            className="textfield"
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={meterReading}
                                            onChange={(e) => setMeterReading(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fueltype"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fuel Type*
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Fuel Type"
                                            variant="outlined"
                                            className={
                                                fueltypeError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={fueltype}
                                            onChange={(e) => setFueltype(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fueltype"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Color *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Color"
                                            variant="outlined"
                                            className={
                                                vehicleColorError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleColor}
                                            onChange={(e) => setVehicleColor(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehicleWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Gross Weight Of Vehicle *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Gross Weight Of Vehicle"
                                            variant="outlined"
                                            className={
                                                vehicleWeightError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={vehicleWeight}
                                            onChange={(e) => setVehicleWeight(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="ownerSerialNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Owner Serial No *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Vehicle Owner Serial No"
                                            variant="outlined"
                                            className={
                                                ownerSerialNoError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={ownerSerialNo}
                                            onChange={(e) => setOwnerSerialNo(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="ownerSerialNo"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Hypothecation Bank Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Hypothecation Bank"
                                            variant="outlined"
                                            className={
                                                hypothecationBankError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={hypothecationBank}
                                            onChange={(e) => setHypothecationBank(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceCompany"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Company Name *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Hypothecation Bank"
                                            variant="outlined"
                                            className="textfield"
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={insuranceCompany}
                                            onChange={(e) => setInsuranceCompany(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsuranceStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Insurance Expired *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-Insurance-Expired">Insurance Expired</InputLabel>
                                        <Select
                                            labelId="demo-simple-Insurance-Expired"
                                            id="demo-simple-select"
                                            value={insuranceStatus}
                                            label="Insurance Status"
                                            onChange={(e) => selectInsuranceStatusChange(e)}

                                        >
                                            {options.map((test, index) =>
                                                <MenuItem value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="cubicCapacity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Cubic  Capacity *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Cubic Capacity"
                                            variant="outlined"
                                            className={
                                                cubicCapacityError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={cubicCapacity}
                                            onChange={(e) => setCubicCapacity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="seatingCapacity"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Seating Capacity *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Seating Capacity"
                                            variant="outlined"
                                            className={
                                                eatingCapacityError !== ""
                                                    ? "textfield error_class"
                                                    : "textfield "
                                            }
                                            style={{ marginTop: '5px' }}
                                            type="text"
                                            id="text"
                                            value={seatingCapacity}
                                            onChange={(e) => setSeatingCapacity(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-span-3 space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Video *
                                    </label>
                                    {
                                        showLoader === true ? (<>
                                            <div style={{ height: '250px', width: '50%', backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        </>) : (<>
                                            <iframe
                                                title="Video Player"
                                                width="50%" // Set width to 100% to fill the container
                                                height="250px" // Set height to auto to maintain aspect ratio
                                                src={videoRecord}
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                        </>)
                                    }
                                    <input type="file" id='videouploading' onChange={handleFileChange} accept="video/*" />
                                    {
                                        selectedVideo ? (
                                            <div>
                                                <button onClick={handleUpload} style={{ backgroundColor: '#ff6600', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>) : (
                                            <div>
                                                <button style={{ backgroundColor: '#ff6600', opacity: '.5', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>)
                                    }
                                </div>
                                <div className="col-span-3 space-y-1">
                                    <ImageUpload />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="vehicleWeight"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Selfie With Vehicle *
                                    </label>
                                    <input type="file" onChange={handleSelfieWithVehicleChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isSelfieWithAgentLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={selfieWithVehicleImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="Chasis Image"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis Image *
                                    </label>
                                    <input type="file" onChange={handleChasisChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isChasisImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={chasisImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label
                                        htmlFor="formatfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Product Format Image *
                                    </label>
                                    <input type="file" onChange={handleFormatChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {IsformatImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={formatImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="openBonnetfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Bonnet Image *
                                    </label>
                                    <input type="file" onChange={handleOpenBonnetChange}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOpenBonnetLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={openBonnet} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="openBonnetfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Right 45* Image *
                                    </label>
                                    <input type="file" onChange={handleright45Change}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRight45ImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={right45image} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Image *
                                    </label>
                                    <input type="file" onChange={frontImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isFrontImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={frontimage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontUnderCar"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Under Car Image *
                                    </label>
                                    <input type="file" onChange={frontUnderCarImg}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isFrontUnderCarImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={frontUnderCarImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="left45file"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Left 45* Image *
                                    </label>
                                    <input type="file" onChange={handlesetLeft45Image}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isLeft45Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={left45image} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="leftfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Left Image *
                                    </label>
                                    <input type="file" onChange={handlesetLeftImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isLeftImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={leftImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Rear Image *
                                    </label>
                                    <input type="file" onChange={handlesetRearImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRearImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rearImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearunderpartfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Car Rear Under Part Image *
                                    </label>
                                    <input type="file" onChange={handlerearUnderPartImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRearUnderPartLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rearUnderPartImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rearunderpartfile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Dickey Image *
                                    </label>
                                    <input type="file" onChange={handlerearOpenDickeyImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOpenDickeyImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={openDickeyImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="handlerightImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Handle Right Image *
                                    </label>
                                    <input type="file" onChange={HandlerightImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRightImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rightImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="tyreImage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tyre Image *
                                    </label>
                                    <input type="file" onChange={handlesetTyreImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isTyreImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={tyreImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="glassOutside"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Glass Outside Image*
                                    </label>
                                    <input type="file" onChange={handleGlassOutsideImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isGlassOutsideImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={glassOutsideImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="glassOutside"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Glass Inside Image*
                                    </label>
                                    <input type="file" onChange={handleGlassInsideImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isGlassInsideImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={glassInsideImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="odometer"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Odometer Image*
                                    </label>
                                    <input type="file" onChange={handleOdometerImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isOdometerImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={odometerImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="rpmFile"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        RPM Image*
                                    </label>
                                    <input type="file" onChange={handleRpmImage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRpmImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rpmImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Any Damage *
                                    </label>
                                    <input type="file" onChange={handleAnyDamage}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isAnyDamageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={anyDamage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Damage *
                                    </label>
                                    <input type="file" onChange={handleExtraImage}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 1 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage1}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage1Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage1} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 2 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage2}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage2Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage2} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="anyDamage"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 3 *
                                    </label>
                                    <input type="file" onChange={handleExtraImage3}
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isExtraImage3Loading ? (
                                            <div></div>
                                        ) : (
                                            <img src={extraImage3} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="signature"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Your Signature*
                                    </label>

                                    <input type="file" onChange={handleSignature}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isSignatureImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={signatureImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="frontRc"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Frontside of your RC*
                                    </label>
                                    <input type="file" onChange={handleFrontRc}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRcFrontImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rcFrontImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="backRc"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Upload Frontside of your RC*
                                    </label>
                                    <input type="file" onChange={handleBackRc}
                                        className={
                                            vehicleWeightError !== ""
                                                ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                                                : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                    />
                                    <div>
                                        {isRcBackImageLoading ? (
                                            <div></div>
                                        ) : (
                                            <img src={rcBackImage} style={{ height: '100px', width: '100px' }} alt="img" />
                                        )}
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fOdometerType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Odometer Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-odometerType">Odometer Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-odometerType"
                                            id="demo-simple-select"
                                            value={odometerType}
                                            label="odometerType"
                                            onChange={(e) => selectOdometerTypeChange(e)}

                                        >
                                            {odometerList.map((test, index) =>
                                                <MenuItem selected={test.name === odometerType} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fFitnessExpiryDate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fitness Expiry Date *
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fFitnessExpiryDate"
                                        placeholder="Enter Fitness Expiry Date"
                                        value={fitnessExpiryDate}
                                        onChange={(e) => setFitnessExpiryDate(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRoadTaxValidityDate"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        RoadTax Validity Date *
                                    </label>
                                    <input
                                        type="date"
                                        className={
                                            "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                                        }
                                        id="fRoadTaxValidityDate"
                                        placeholder="Enter Fitness Expiry Date"
                                        value={roadTaxValidityDate}
                                        onChange={(e) => setRoadTaxValidity(e.target.value)}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fEngineCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-engineCondition">Engine Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-engineCondition"
                                            id="demo-simple-select"
                                            value={engineCondition}
                                            label="engineCondition"
                                            onChange={(e) => selectEngineConditionChange(e)}

                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem selected={test.name === engineCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fFuelTankCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Fuel Tank Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-fuelTankCondition">FuelTank Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-fuelTankCondition"
                                            id="demo-simple-select"
                                            value={fuelTankCondition}
                                            label="fuelTankCondition"
                                            onChange={(e) => selectFuelTankConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === fuelTankCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fEngineSheildCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Engine Sheild Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-engineShield">Engine Sheild Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-engineShield"
                                            id="demo-simple-select"
                                            value={engineShield}
                                            label="engineShield"
                                            onChange={(e) => selectEngineSheildConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === engineShield} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fTransmissionType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Transmission Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-transmissionType">Transmission Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-transmissionType"
                                            id="demo-simple-select"
                                            value={transmissionType}
                                            label="transmissionType"
                                            onChange={(e) => selectTransmissionTypeChange(e)}
                                        >
                                            {typeList.map((test, index) =>
                                                <MenuItem selected={test.name === transmissionType} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fTransmissionCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Transmission Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-transmissionCondition">Transmission Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-transmissionCondition"
                                            id="demo-simple-select"
                                            value={transmissionCondition}
                                            label="transmissionCondition"
                                            onChange={(e) => selectTransmissionConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem selected={test.name === transmissionCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fSuspensionCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Suspension Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-suspensionCondition">Suspension Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-suspensionCondition"
                                            id="demo-simple-select"
                                            value={suspensionCondition}
                                            label="suspensionCondition"
                                            onChange={(e) => selectSuspensionConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem selected={test.name === suspensionCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fFrontBreakCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Break Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-frontBreakCondition">Front Break Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-frontBreakCondition"
                                            id="demo-simple-select"
                                            value={frontBreakCondition}
                                            label="frontBreakCondition"
                                            onChange={(e) => selectFrontBreakConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem selected={test.name === frontBreakCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRearBreakCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Break Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-rearBreakCondition">Rear Break Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-rearBreakCondition"
                                            id="demo-simple-select"
                                            value={rearBreakCondition}
                                            label="rearBreakCondition"
                                            onChange={(e) => selectRearBreakConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem selected={test.name === rearBreakCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fHandBreakCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        HandBreak Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-handBrake">HandBrake Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-handBrake"
                                            id="demo-simple-select"
                                            value={handBrake}
                                            label="handBrake"
                                            onChange={(e) => selectHandBreakConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === handBrake} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fWheelType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Wheel Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-wheelType">Wheel Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-wheelType"
                                            id="demo-simple-select"
                                            value={wheelType}
                                            label="wheelType"
                                            onChange={(e) => selectWheelTypeChange(e)}
                                        >
                                            {wheelTypeList.map((test, index) =>
                                                <MenuItem selected={test.name === wheelType} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fFrontTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Tyre Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleFront">Front Tyre Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleFront"
                                            id="demo-simple-select"
                                            value={vehicleFront}
                                            label="vehicleFront"
                                            onChange={(e) => selectFrontTyreConditionChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleFront} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLhRear"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Lh Rear Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleLhRear">Lh Rear Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleLhRear"
                                            id="demo-simple-select"
                                            value={vehicleLhRear}
                                            label="vehicleLhRear"
                                            onChange={(e) => selectVehicleLhRearConditionChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleLhRear} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRhRear"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rh Rear Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleRhRear">Rh Rear Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleRhRear"
                                            id="demo-simple-select"
                                            value={vehicleRhRear}
                                            label="vehicleRhRear"
                                            onChange={(e) => selectVehicleRhRearConditionChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleRhRear} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fSpareTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Spare Tyre Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleSpare">Spare Tyre Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleSpare"
                                            id="demo-simple-select"
                                            value={vehicleSpare}
                                            label="vehicleSpare"
                                            onChange={(e) => selectVehicleSpareTyreConditionChange(e)}
                                        >
                                            {list.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleSpare} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fTotalTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Total Tyre *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Total Tyre"
                                            variant="outlined"
                                            className={`textfield`}
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={totalTyre}
                                            onChange={(e) => setTotalTyre(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fAvailableTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Available Tyre *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Available Tyre"
                                            variant="outlined"
                                            className={`textfield`}
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={availableTyre}
                                            onChange={(e) => setAvailableTyre(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fMissingTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Missing Tyre *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Missing Tyre"
                                            variant="outlined"
                                            className={`textfield`}
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={missingTyre}
                                            onChange={(e) => setMissingTyre(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fSpareTyre"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Spare Tyre *
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Spare Tyre"
                                            variant="outlined"
                                            className={`textfield`}
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={spareTyre}
                                            onChange={(e) => setSpareTyre(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fSeatCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Seat Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleSeat">Seat Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleSeat"
                                            id="demo-simple-select"
                                            value={vehicleSeat}
                                            label="vehicleSeat"
                                            onChange={(e) => selectVehicleSeatConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleSeat} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInteriorTrims"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Interior Trims Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleInteriorTrims">Interior Trims Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleInteriorTrims"
                                            id="demo-simple-select"
                                            value={vehicleInteriorTrims}
                                            label="vehicleInteriorTrims"
                                            onChange={(e) => selectVehicleInteriorTrimsConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleInteriorTrims} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fInsideRearView"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Inside Rear View Mirror Available *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleInsideRearViewMirror">Rear View Mirror</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleInsideRearViewMirror"
                                            id="demo-simple-select"
                                            value={vehicleInsideRearViewMirror}
                                            label="vehicleInsideRearViewMirror"
                                            onChange={(e) => selectVehicleInsideRearViewMirrorChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleInsideRearViewMirror} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fBodyType"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Body Type *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleBodyType">Body Type</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleBodyType"
                                            id="demo-simple-select"
                                            value={vehicleBodyType}
                                            label="vehicleBodyType"
                                            onChange={(e) => selectVehicleBodyTypeChange(e)}
                                        >
                                            {vehcileBodyType.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleBodyType} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fFrontMudGuard"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front MudGuard Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleFrontMudGuard">Front MudGuard Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleFrontMudGuard"
                                            id="demo-simple-select"
                                            value={vehicleFrontMudGuard}
                                            label="vehicleFrontMudGuard"
                                            onChange={(e) => selectVehicleFrontMudGuardCondtionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleFrontMudGuard} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRearMudGuard"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Wheel MudGuard Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleRearWheelMudGuard">Rear MudGuard Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleRearWheelMudGuard"
                                            id="demo-simple-select"
                                            value={vehicleRearWheelMudGuard}
                                            label="vehicleRearWheelMudGuard"
                                            onChange={(e) => selectVehicleRearWheelMudGuardCondtionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleRearWheelMudGuard} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fWindScreen"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Wind Screen Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleWindScreen">Wind Screen Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleWindScreen"
                                            id="demo-simple-select"
                                            value={vehicleWindScreen}
                                            label="vehicleWindScreen"
                                            onChange={(e) => selectVehicleWindScreenCondtionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleWindScreen} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fWindScreenGlass"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Wind Screen Glass Status *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleWindScreenGlass">Wind Screen Glass Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleWindScreenGlass"
                                            id="demo-simple-select"
                                            value={vehicleWindScreenGlass}
                                            label="vehicleWindScreenGlass"
                                            onChange={(e) => selectVehicleWindScreenGlassChange(e)}
                                        >
                                            {qualityList.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleWindScreenGlass} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fSideDoor"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Side Door Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleSideDoor">Side Door Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleSideDoor"
                                            id="demo-simple-select"
                                            value={vehicleSideDoor}
                                            label="vehicleSideDoor"
                                            onChange={(e) => selectVehicleSideDoorChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleSideDoor} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fDoorGlasses"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Door Glasses Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleDoorGlasses">Door Glasses Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleDoorGlasses"
                                            id="demo-simple-select"
                                            value={vehicleDoorGlasses}
                                            label="vehicleDoorGlasses"
                                            onChange={(e) => selectVehicleDoorGlassesConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleDoorGlasses} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fDoorGlasses"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        OutSide Rear View Mirrors Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleOutsideRearViewMirror">OutSide Rear View Mirrors Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleOutsideRearViewMirror"
                                            id="demo-simple-select"
                                            value={vehicleOutsideRearViewMirror}
                                            label="vehicleOutsideRearViewMirror"
                                            onChange={(e) => selectVehicleOutsideRearViewMirrorChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleOutsideRearViewMirror} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fPaint"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Paint Quality *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehiclePaint">Paint Quality</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehiclePaint"
                                            id="demo-simple-select"
                                            value={vehiclePaint}
                                            label="vehiclePaint"
                                            onChange={(e) => selectPaintChange(e)}
                                        >
                                            {qualityList.map((test, index) =>
                                                <MenuItem selected={test.name === vehiclePaint} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fPaintCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Paint Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehiclePaintCondition">Paint Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehiclePaintCondition"
                                            id="demo-simple-select"
                                            value={vehiclePaintCondition}
                                            label="vehiclePaintCondition"
                                            onChange={(e) => selectPaintConditionChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehiclePaintCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fOverAll"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        OverAll Body Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-AllBodyCondition">OverAll Body Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-AllBodyCondition"
                                            id="demo-simple-select"
                                            value={vehicleOverAllBodyCondition}
                                            label="vehicleOverAllBodyCondition"
                                            onChange={(e) => selectOverAllBodyConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleOverAllBodyCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fBattery"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Battery Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleBattery">Battery Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleBattery"
                                            id="demo-simple-select"
                                            value={vehicleBattery}
                                            label="vehicleBattery"
                                            onChange={(e) => selectVehicleBatteryChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleBattery} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fBattery"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        HeadLamp Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleHeadLamp">HeadLamp Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleHeadLamp"
                                            id="demo-simple-select"
                                            value={vehicleHeadLamp}
                                            label="vehicleHeadLamp"
                                            onChange={(e) => SelectVehicleHeadLampChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleHeadLamp} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fFrontIndicator"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Indicator Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleFrontIndicators">Front Indicator Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleFrontIndicators"
                                            id="demo-simple-select"
                                            value={vehicleFrontIndicators}
                                            label="vehicleFrontIndicators"
                                            onChange={(e) => SelectFrontIndicatorsChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleFrontIndicators} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fRearIndicator"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Indicator Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleRearIndicators">Rear Indicator Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleRearIndicators"
                                            id="demo-simple-select"
                                            value={vehicleRearIndicators}
                                            label="vehicleRearIndicators"
                                            onChange={(e) => SelectRearIndicatorsChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleRearIndicators} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fTailLamp"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tail Lamp Condition *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleTailLamp">Tail Lamp Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleTailLamp"
                                            id="demo-simple-select"
                                            value={vehicleTailLamp}
                                            label="vehicleTailLamp"
                                            onChange={(e) => SelectTailLampChange(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleTailLamp} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fElectricalCondition"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Electrical Condition <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-electricalCondition">Electrical Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-electricalCondition"
                                            id="demo-simple-select"
                                            value={electricalCondition}
                                            label="electricalCondition"
                                            onChange={(e) => selectElectricalConditionChange(e)}
                                        >
                                            {reqCondition.map((test, index) =>
                                                <MenuItem selected={test.name === electricalCondition} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fToolkit"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Toolkit Available*
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleToolkit">Toolkit Condition</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleToolkit"
                                            id="demo-simple-select"
                                            value={vehicleToolkit}
                                            label="vehicleToolkit"
                                            onChange={(e) => SelectVehicleToolkitChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleToolkit} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fOtherAccessories"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Other Accessories Available*
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-vehicleOthersAccessories">Other Accessories Available</InputLabel>
                                        <Select
                                            labelId="demo-simple-vehicleOthersAccessories"
                                            id="demo-simple-select"
                                            value={vehicleOthersAccessories}
                                            label="vehicleOthersAccessories"
                                            onChange={(e) => SelectVehicleOthersAccessoriesChange(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === vehicleOthersAccessories} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fAccidentalStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Accidental Status *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-accidental">Accidental Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-accidental"
                                            id="demo-simple-select"
                                            value={accidental}
                                            label="accidental"
                                            onChange={(e) => SelectAccidentalStatus(e)}
                                        >
                                            {options.map((test, index) =>
                                                <MenuItem selected={test.name === accidental} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLevelOfMaintenance"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Level Of Maintenance *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-maintenanceLevel">Level Of Maintenance</InputLabel>
                                        <Select
                                            labelId="demo-simple-maintenanceLevel"
                                            id="demo-simple-select"
                                            value={maintenanceLevel}
                                            label="maintenanceLevel"
                                            onChange={(e) => SelectMaintenanceLevelCondition(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === maintenanceLevel} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fOtherRepairStatus"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Other Repair Status *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-otherRepair">Other Repair Status</InputLabel>
                                        <Select
                                            labelId="demo-simple-otherRepair"
                                            id="demo-simple-select"
                                            value={otherRepair}
                                            label="otherRepair"
                                            onChange={(e) => SelectotherRepairStatus(e)}
                                        >
                                            {condition.map((test, index) =>
                                                <MenuItem selected={test.name === otherRepair} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fYourRemark"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Your Remark *
                                    </label>
                                    <FormControl fullWidth style={{ marginTop: '12px' }} className="textfield">
                                        <InputLabel id="demo-simple-yourRemark">Your Remark</InputLabel>
                                        <Select
                                            labelId="demo-simple-yourRemark"
                                            id="demo-simple-select"
                                            value={yourRemark}
                                            label="yourRemark"
                                            onChange={(e) => setYourRemark(e.target.value)}
                                        >
                                            {selectRemarkType.map((test, index) =>
                                                <MenuItem selected={test.name === yourRemark} value={test.name} key={index}>{test.name}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fExShowRoomPrice"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Ex-ShowRoom Price <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Ex-ShowRoom Price"
                                            variant="outlined"
                                            className={
                                                exShowRoomPriceError === ""
                                                    ? `textfield`
                                                    : `textfield error_class`
                                            }
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={exShowRoomPrice}
                                            onChange={(e) => setExShowRoomPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fEstimatedPrice"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Estimated Price <span style={{ color: 'red' }}>**</span>
                                    </label>
                                    <div className="textfieldcontainer">
                                        <TextField
                                            label="Estimated Price"
                                            variant="outlined"
                                            className={
                                                estimatedPriceError === ""
                                                    ? `textfield`
                                                    : `textfield error_class`
                                            }
                                            style={{ marginTop: '3px' }}
                                            type="text"
                                            id="text"
                                            value={estimatedPrice}
                                            onChange={(e) => setEstimatedPrice(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '80px' }}>
                        <button
                            onClick={(e) => cancelClick(e)}
                            className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border border-transparent align-middle duration-500 text-sm text-center text-red-600 hover:border-red-500/20 hover:bg-red-500/20 rounded transition-all"
                            style={{ outline: 'none', border: 'none', color: '#fff', backgroundColor: 'red' }}
                        >
                            <i className="ph-bold ph-x text-lg" /> cancel
                        </button>
                        {
                            buttonLoading ?
                                <button
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                    style={{ background: "grey", outline: 'none', border: 'none' }}
                                >
                                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                    Submitting
                                </button>
                                : <button
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                    onClick={(e) => handlesubmit(e)}
                                    style={{ background: "#4D44B5", outline: 'none', border: 'none' }}
                                >
                                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                    Submit
                                </button>
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

export default AddThreeWheelerCase;
