import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Baseurl } from '../../config/BaseUrl'
import axios from 'axios'
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from 'react-redux';
import { CaseActions, UploadChasisImage, UploadFrontImage, UploadOpenBonnet, UploadRearUnderPart, UploadRight45Image, selfieWithVehicleUpload, uploadAnyDamage, uploadExtraImage, uploadExtraImage1, uploadExtraImage2, uploadExtraImage3, uploadFrontUnderCar, uploadGlassInsideImage, uploadGlassOutside, uploadLeft45, uploadLeftImage, uploadOdometerImage, uploadOpenDickey, uploadRcBackImage, uploadRcFront, uploadRearImage, uploadRightImage, uploadRpmImage, uploadSignature, uploadTyreImage, uploadformatImage } from '../../redux/Cases/CaseSlice';
import { CloudinaryContext, Video } from 'cloudinary-react';
import Spinner from 'react-bootstrap/Spinner';
import { saveAs } from 'file-saver';
import { FiDownload } from "react-icons/fi";
import { FaUpload } from 'react-icons/fa';
import ImageUpload from '../cases/UploadImage';

const UploadPhotos = () => {
    const {
        selfieWithVehicleImage,
        isSelfieWithAgentLoading,
        chasisImage,
        isChasisImageLoading,
        IsformatImageLoading,
        formatImage,
        isOpenBonnetLoading,
        openBonnet,
        isRight45ImageLoading,
        right45image,
        frontimage,
        isFrontImageLoading,
        frontUnderCarImage,
        isFrontUnderCarImageLoading,
        isLeft45Loading,
        left45image,
        isLeftImageLoading,
        leftImage,
        rearImage,
        isRearImageLoading,
        rearUnderPartImage,
        isRearUnderPartLoading,
        openDickeyImage,
        isOpenDickeyImageLoading,
        rightImage,
        isRightImageLoading,
        tyreImage,
        isTyreImageLoading,
        glassOutsideImage,
        isGlassOutsideImageLoading,
        glassInsideImage,
        isGlassInsideImageLoading,
        odometerImage,
        isOdometerImageLoading,
        rpmImage,
        isRpmImageLoading,
        anyDamage,
        isAnyDamageLoading,
        extraImage,
        isExtraImageLoading,
        extraImage1,
        isExtraImage1Loading,
        extraImage2,
        isExtraImage2Loading,
        extraImage3,
        isExtraImage3Loading,
        signatureImage,
        isSignatureImageLoading,
        rcFrontImage,
        isRcFrontImageLoading,
        rcBackImage,
        isRcBackImageLoading,
    } = useSelector((store) => store.cases);
    const params = useParams()
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const [videoRecord, setVideoRecord] = useState('')
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showLoader, setShowLoader] = useState(false)
    // ------*****-----------errors-----------*****------

    const handleCaseUpdate = async () => {
        const formData = {
            videos: videoRecord,
            images: {
                selfieWithVehicle: selfieWithVehicleImage,
                chasisImage: chasisImage,
                format: formatImage,
                openBonnet: openBonnet,
                right45: right45image,
                frontImage: frontimage,
                frontUnderCar: frontUnderCarImage,
                left45: left45image,
                leftImage: leftImage,
                rearImage: rearImage,
                rearUnderPart: rearUnderPartImage,
                openDickey: openDickeyImage,
                rightImage: rightImage,
                tyreImage: tyreImage,
                glassOutside: glassOutsideImage,
                glassInside: glassInsideImage,
                odometerImage: odometerImage,
                rpmImage: rpmImage,
                anyDamage: anyDamage,
                extraImage: extraImage,
                extraImage1: extraImage1,
                extraImage2: extraImage2,
                extraImage3: extraImage3,
            },
            signNdoc: {
                sign: signatureImage,
                rcFront: rcFrontImage,
                rcBack: rcBackImage,
            },
            status: 4,
            statusText: 'Case Submitted',
        };
        const config = {
            Headers: { 'Content-Type': 'application/json' },
        };
        const url = `${Baseurl}/api/v1/cases/updatecase/${params.id}`;
        const resp = await axios.put(url, formData, config);
        if (resp.data.success) {
            alert('Case submitted successfully')
            navigation("/admin/submittedcases")
        }
    }


    const fileInputRef = useRef(null);

    // Function to handle file input change
    const handleFileInputChange = () => {
        const file = fileInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(selfieWithVehicleUpload({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };


    const chasisInputRef = useRef(null);

    // Function to handle file input change
    const chasisInputChange = () => {
        const file = chasisInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(UploadChasisImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const chasisButtonClick = () => {
        chasisInputRef.current.click();
    };

    const formatInputRef = useRef(null);

    // Function to handle file input change
    const formatInputChange = () => {
        const file = formatInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadformatImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const formatButtonClick = () => {
        formatInputRef.current.click();
    };

    const openBonnetInputRef = useRef(null);

    // Function to handle file input change
    const openBonnetInputChange = () => {
        const file = openBonnetInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(UploadOpenBonnet({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const openBonnetButtonClick = () => {
        openBonnetInputRef.current.click();
    };

    const right45InputRef = useRef(null);

    // Function to handle file input change
    const right45InputChange = () => {
        const file = right45InputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(UploadRight45Image({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const right45ButtonClick = () => {
        right45InputRef.current.click();
    };

    const frontImageInputRef = useRef(null);

    // Function to handle file input change
    const frontImageInputChange = () => {
        const file = frontImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(UploadFrontImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const frontImageButtonClick = () => {
        frontImageInputRef.current.click();
    };


    const frontUnderCarInputRef = useRef(null);

    // Function to handle file input change
    const frontUnderCarInputChange = () => {
        const file = frontUnderCarInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadFrontUnderCar({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const frontUnderCarButtonClick = () => {
        frontUnderCarInputRef.current.click();
    };


    const leftImageInputRef = useRef(null);

    // Function to handle file input change
    const leftImageInputChange = () => {
        const file = leftImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadLeftImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const leftImageButtonClick = () => {
        leftImageInputRef.current.click();
    };

    const left45ImageInputRef = useRef(null);

    // Function to handle file input change
    const left45ImageInputChange = () => {
        console.log("hii");
        const file = left45ImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadLeft45({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const left45ImageButtonClick = () => {
        left45ImageInputRef.current.click();
    };


    const rearImageInputRef = useRef(null);

    // Function to handle file input change
    const rearImageInputChange = () => {
        const file = rearImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadRearImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const rearImageButtonClick = () => {
        rearImageInputRef.current.click();
    };


    const rearUnderImageInputRef = useRef(null);

    // Function to handle file input change
    const rearUnderImageInputChange = () => {
        const file = rearUnderImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(UploadRearUnderPart({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const rearUnderImageButtonClick = () => {
        rearUnderImageInputRef.current.click();
    };

    const openDickeyImageInputRef = useRef(null);

    // Function to handle file input change
    const openDickeyImageInputChange = () => {
        const file = openDickeyImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadOpenDickey({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const openDickeyImageButtonClick = () => {
        openDickeyImageInputRef.current.click();
    };

    const rightImageInputRef = useRef(null);

    // Function to handle file input change
    const rightImageInputChange = () => {
        const file = rightImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadRightImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const rightImageButtonClick = () => {
        rightImageInputRef.current.click();
    };

    const tyreImageInputRef = useRef(null);

    // Function to handle file input change
    const tyreImageInputChange = () => {
        const file = tyreImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadTyreImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const tyreImageButtonClick = () => {
        tyreImageInputRef.current.click();
    };

    const wsGlassOutsideImageInputRef = useRef(null);

    // Function to handle file input change
    const wsGlassOutsideImageInputChange = () => {
        const file = wsGlassOutsideImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadGlassOutside({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const wsGlassOutsideImageButtonClick = () => {
        wsGlassOutsideImageInputRef.current.click();
    };


    const wsGlassInsideImageInputRef = useRef(null);

    // Function to handle file input change
    const wsGlassInsideImageInputChange = () => {
        const file = wsGlassInsideImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadGlassInsideImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const wsGlassInsideImageButtonClick = () => {
        wsGlassInsideImageInputRef.current.click();
    };
    const odometerImageInputRef = useRef(null);

    // Function to handle file input change
    const odometerImageInputChange = () => {
        const file = odometerImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadOdometerImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const odometerImageButtonClick = () => {
        odometerImageInputRef.current.click();
    };


    const rpmImageInputRef = useRef(null);

    // Function to handle file input change
    const rpmImageInputChange = () => {
        const file = rpmImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadRpmImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const rpmImageButtonClick = () => {
        rpmImageInputRef.current.click();
    };


    const anyDamageImageInputRef = useRef(null);

    // Function to handle file input change
    const anyDamageImageInputChange = () => {
        const file = anyDamageImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadAnyDamage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const anyDamageImageButtonClick = () => {
        anyDamageImageInputRef.current.click();
    };


    const extraImageInputRef = useRef(null);

    // Function to handle file input change
    const extraImageInputChange = () => {
        const file = extraImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadExtraImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const extraImageButtonClick = () => {
        extraImageInputRef.current.click();
    };
    const extraImage1InputRef = useRef(null);

    // Function to handle file input change
    const extraImage1InputChange = () => {
        const file = extraImage1InputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadExtraImage1({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const extraImage1ButtonClick = () => {
        extraImage1InputRef.current.click();
    };

    const extraImage2InputRef = useRef(null);

    // Function to handle file input change
    const extraImage2InputChange = () => {
        const file = extraImage2InputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadExtraImage2({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const extraImage2ButtonClick = () => {
        extraImage2InputRef.current.click();
    };

    const extraImage3InputRef = useRef(null);

    // Function to handle file input change
    const extraImage3InputChange = () => {
        const file = extraImage3InputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadExtraImage3({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const extraImage3ButtonClick = () => {
        extraImage3InputRef.current.click();
    };

    const signatureImageInputRef = useRef(null);

    // Function to handle file input change
    const signatureImageInputChange = () => {
        const file = signatureImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadSignature({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const signatureImageButtonClick = () => {
        signatureImageInputRef.current.click();
    };


    const rcFrontImageInputRef = useRef(null);

    // Function to handle file input change
    const rcFrontImageInputChange = () => {
        const file = rcFrontImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadRcFront({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const rcFrontImageButtonClick = () => {
        rcFrontImageInputRef.current.click();
    };

    const rcBackImageInputRef = useRef(null);

    // Function to handle file input change
    const rcBackImageInputChange = () => {
        const file = rcBackImageInputRef.current.files[0];
        // Handle the selected file...
        const reader = new FileReader();
        reader.onload = async () => {
            if (reader.readyState === 2) {
                const image = await dispatch(uploadRcBackImage({ image: reader.result }));
            }
        };
        reader.readAsDataURL(file);
    };

    // Function to trigger file input click
    const rcBackImageButtonClick = () => {
        rcBackImageInputRef.current.click();
    };
    const handleFileChange = (event) => {
        setSelectedVideo(event.target.files[0])
    };

    // Function to handle form submission (upload)
    const handleUpload = async () => {
        setShowLoader(true)
        const apiKey = '918618947122224';
        const cloudName = 'dexterous-technology';
        const uploadPreset = 'yrevfjck';
        if (selectedVideo) {
            const formData = new FormData();
            formData.append('file', selectedVideo);
            formData.append('upload_preset', 'yrevfjck'); // Replace 'your_upload_preset' with your Cloudinary upload preset

            try {
                const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/video/upload`, {
                    method: 'POST',
                    body: formData
                });

                if (response.ok) {
                    const responseData = await response.json();
                    setVideoRecord(responseData.secure_url)
                    document.getElementById('videouploading').value = '';
                    setShowLoader(false)
                } else {
                    // Handle upload failure
                    console.error('Failed to upload video to Cloudinary:', response.statusText);
                }
            } catch (error) {
                // Handle network or server errors
                console.error('Error uploading video to Cloudinary:', error);
            }
        } else {
            console.log("No video selected.");
        }
    };

    return (
        <>
            <div className="min-h-screen flex flex-col lg:ps-64 w-full">
                <div className="p-2 space-y-6">
                    <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
                        <div className="flex items-center justify-between py-2 px-5"
                            style={{ backgroundColor: '#4D44B5', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                        >
                            <h4 className="text-lg font-medium text-default-950 capitalize" style={{ color: '#fff' }}>
                                Upload Images
                            </h4>
                        </div>
                        <div className="p-5 border-t border-dashed border-default-200">
                            <div className="grid md:grid-cols-3 gap-4">
                                <div className="col-span-3 space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Vehicle Video *
                                    </label>
                                    {
                                        showLoader === true ? (<>
                                            <div style={{ height: '250px', width: '50%', backgroundColor: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div>
                                        </>) : (<>
                                            <iframe
                                                title="Video Player"
                                                width="50%" // Set width to 100% to fill the container
                                                height="250px" // Set height to auto to maintain aspect ratio
                                                src={videoRecord}
                                                frameBorder="0"
                                                allowFullScreen
                                            ></iframe>
                                        </>)
                                    }
                                    <input type="file" id='videouploading' onChange={handleFileChange} accept="video/*" />
                                    {
                                        selectedVideo ? (
                                            <div>
                                                <button onClick={handleUpload} style={{ backgroundColor: '#ff6600', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>) : (
                                            <div>
                                                <button style={{ backgroundColor: '#ff6600', opacity: '.5', color: '#fff', padding: '5px 8px', borderRadius: '4px' }}>Upload Video</button>
                                            </div>)
                                    }
                                </div>
                                <div className="col-span-3 space-y-1">
                                    <ImageUpload />
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Selfie With vehicle *
                                    </label>

                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                            <img id='selfieWithVehicle' src={selfieWithVehicleImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={handleButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Chasis Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='chasisImage' src={chasisImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={chasisInputRef}
                                                style={{ display: 'none' }}
                                                onChange={chasisInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={chasisButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Format Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='formatImage' src={formatImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={formatInputRef}
                                                style={{ display: 'none' }}
                                                onChange={formatInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={formatButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Bonnet Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='openBonnetImage' src={openBonnet} style={{ height: '180px', width: '240px' }} alt="img" />

                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={openBonnetInputRef}
                                                style={{ display: 'none' }}
                                                onChange={openBonnetInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={openBonnetButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Right 45* Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='right45Image' src={right45image} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={right45InputRef}
                                                style={{ display: 'none' }}
                                                onChange={right45InputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={right45ButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='frontImage' src={frontimage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={frontImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={frontImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={frontImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Front Under Car Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='frontUnderCarImage' src={frontUnderCarImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={frontUnderCarInputRef}
                                                style={{ display: 'none' }}
                                                onChange={frontUnderCarInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={frontUnderCarButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Left Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='leftImage' src={leftImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={leftImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={leftImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={leftImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Left 45* Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='leftFront45Image' src={left45image} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={left45ImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={left45ImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={left45ImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rearImage' src={rearImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={rearImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={rearImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={rearImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rear Under Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rearUnderImage' src={rearUnderPartImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={rearUnderImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={rearUnderImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={rearUnderImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Open Dickey Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='openDickeyImage' src={openDickeyImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={openDickeyImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={openDickeyImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={openDickeyImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Right Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rightImage' src={rightImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={rightImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={rightImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={rightImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Tyre Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='tyreImage' src={tyreImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={tyreImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={tyreImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={tyreImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Ws Glass Outside Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='wsGlassOutsideImage' src={glassOutsideImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={wsGlassOutsideImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={wsGlassOutsideImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={wsGlassOutsideImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Ws Glass Inside Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='wsGlassInsideImage' src={glassInsideImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={wsGlassInsideImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={wsGlassInsideImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={wsGlassInsideImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Odometer Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='odometerImage' src={odometerImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={odometerImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={odometerImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={odometerImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rpm Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rpmImage' src={rpmImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={rpmImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={rpmImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={rpmImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Any Damage Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='anyDamageImage' src={anyDamage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={anyDamageImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={anyDamageImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={anyDamageImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='extraImage' src={extraImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={extraImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={extraImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={extraImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 1*
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='extraImage1' src={extraImage1} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={extraImage1InputRef}
                                                style={{ display: 'none' }}
                                                onChange={extraImage1InputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={extraImage1ButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 2*
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='extraImage2' src={extraImage2} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={extraImage2InputRef}
                                                style={{ display: 'none' }}
                                                onChange={extraImage2InputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={extraImage2ButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Extra Image 3*
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='extraImage3' src={extraImage3} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={extraImage3InputRef}
                                                style={{ display: 'none' }}
                                                onChange={extraImage3InputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={extraImage3ButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Signature Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='signatureImage' src={signatureImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={signatureImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={signatureImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={signatureImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rc Front Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rcFrontImage' src={rcFrontImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={rcFrontImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={rcFrontImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={rcFrontImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label
                                        htmlFor="fLoanAgreement"
                                        className="text-sm font-medium text-default-900"
                                    >
                                        Rc Back Image *
                                    </label>
                                    <div style={{ position: 'relative', height: '180px', width: '240px', backgroundColor: '#000' }}>
                                        <img id='rcBackImage' src={rcBackImage} style={{ height: '180px', width: '240px' }} alt="img" />
                                        <div className='upload-image'>
                                            {/* Hidden file input */}
                                            <input
                                                type="file"
                                                ref={rcBackImageInputRef}
                                                style={{ display: 'none' }}
                                                onChange={rcBackImageInputChange}
                                            />
                                            {/* Button with FontAwesome icon to trigger file input click */}
                                            <button onClick={rcBackImageButtonClick}>
                                                <FaUpload />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 justify-end" style={{ marginBottom: '80px', marginTop: '20px' }}>
                                <button
                                    className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
                                    style={{ background: "#4D44B5", border: 'none', outline: 'none' }}
                                    onClick={() => handleCaseUpdate()}
                                >
                                    <i className="ph-duotone ph-cloud-arrow-up text-lg" />
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadPhotos